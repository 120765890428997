import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    Button,
    Dialog,
    Form,
    Grid,
    Input,
    List,
    Loading,
    Mask,
    Modal,
    NavBar,
    Popup,
    TextArea
} from "antd-mobile";
import {
    AppstoreOutline,
    CheckCircleFill,
    DownlandOutline,
    EditSOutline,
    ExclamationCircleFill,
    MessageOutline
} from "antd-mobile-icons";
import {
    CopyOutlined,
    DeleteOutlined,
    LinkOutlined,
    MailOutlined,
    ShareAltOutlined,
    WhatsAppOutlined
} from "@ant-design/icons";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getPublicShareLink, PublicShareLinks} from "../../../utils";
import {appListener, arePermissionsGranted, requestPermissions, sendDataToApp} from "../../../utils/webviewUtils";
import {PRIMARY_COLOR, RUPEE} from "../../../models/constants";
import {Alert, Image, Input as InputB, message} from "antd";
import {deleteVoucher, emailInvoice, getVouchers, baseUrl, sendSMS} from "../../../services/api";
import styles from "../../m_styles.less";
import moment from "moment";
import {useSelector} from "react-redux";
// import {Document, Page, pdfjs} from 'react-pdf';
import {useLayersFramework, useMyParams} from "../../v2framework/framework.hooks";
import ActivityWrapper from "../../components/Activity";
import {voucherTypes} from "../../../models/jsConstants";
import imgEnterprise from "../../../res/img/enterprise.png";
import QuickPinchZoom, {make3dTransformValue} from "react-quick-pinch-zoom";
import { MobileButtonV2 } from "../../components/MobileButtonV2";
import {checkAndRequestPermissionsV2, downloadVoucher} from "../../../utils/webViewUtilsV2/interfaces";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdn.bootcss.com/pdf.js/${pdfjs.version}/pdf.worker.js`;


const PreviewDocumentComponent = ({previewPdfUrl}) => {
    const imgRef = useRef();
    const onUpdate = useCallback(({ x, y, scale }) => {
        const { current: img } = imgRef;
        // console.log("## IMG ##", img);
        if (img) {
            const value = make3dTransformValue({ x, y, scale });
            img.style.setProperty("transform", value);
        }
    }, []);

    return (
        <div style={{width: '100%', height: '100%', border: '0px solid blue',
            overflow: 'hidden'
        }}>
            <QuickPinchZoom onUpdate={onUpdate}
                            // enforceBoundsDuringZoom={true}
            >

                <img
                    ref={imgRef}
                    src={previewPdfUrl}
                    style={{width: '100%', height: 'calc(100vh - 152px)', objectFit: "contain", objectPosition: 'top', border: '0px solid green'}}
                    // src={"https://infinitetelematics.com/panel/img/logo.png"}
                />
            </QuickPinchZoom>
        </div>
    )
}


const VoucherViewActivity = () => {
    const state = useSelector(state => state);
    const [s, params] = useMyParams();
    const {goBack} = useLayersFramework();
    const navigate = useNavigate();
    const {voucherType, voucherId} = params;
    const [showSharePopup, setShowSharePopup] = useState(false);
    const [showWaModal, setShowWaModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [showCopied, setShowCopied] = useState(false);
    const [loading, setLoading] = useState("");
    const docType = voucherTypes[voucherType];
    const [voucher, setVoucher] = useState({});
    const [waForm] = Form.useForm();
    const [emailForm] = Form.useForm();
    const [ref, setRef] = useState();
    const previewRef = useRef(null);

    const publicLink = getPublicShareLink(PublicShareLinks.voucher, voucher?.hashId);

    // const publicLink = getPublicShareLink(PublicShareLinks.VOUCHER, voucher?.hashId);
    const fileName = voucher?.date ?
        `${docType.filePrefix}-${voucher.documentNumber?.prefix || ""}${voucher.documentNumber?.num || ""}-${moment(voucher.date).format('DD-MM-YYYY')}.pdf`
        : 'document.pdf';
    const invoiceNum = `${voucher?.documentNumber?.prefix}${voucher?.documentNumber?.num}`;

    const downloadUrl = `${baseUrl}/internal/pdf/${voucherId}`; 
    const previewPdfUrl = `${baseUrl}/internal/pdf/${voucherId}?output=img`;
    // const downloadUrl = `http://www.africau.edu/images/default/sample.pdf`;

    console.log("## DownloadURL ##", downloadUrl);
    useEffect(() => {
        if (state.company) {
            getVouchers(voucherType, voucherId).then(resp => setVoucher(resp.data.data.vouchers[0]));
        }
    }, [state, voucherId]);

    const downloadFile = (sendNotificationFlag) => {
        return downloadVoucher(downloadUrl, fileName);
    }

    const appPermissionsHandler = async () => {
        const result = await checkAndRequestPermissionsV2(); // "To enjoy this feature, you need to provide storage permission."
        console.log(`Permission Result`, result);
        if (!result) {
            Dialog
                .alert({
                    title: "Error",
                    content: "It seems like you have not provided storage permissions. To enjoy this feature, please allow storage permissions in Settings > Apps > KhataBuddy > Permissions > Storage > Allow",
                    confirmText: "Okay"
                });
        }
        return result;
    };

    const onClickPdf = async () => {
        const result = await appPermissionsHandler();
        if (!result) {
            return;
        }
        setLoading("Downloading PDF");
        const filePath = await downloadFile(true);
        setLoading("");
        // Dialog.confirm({
        //     header: (
        //         <CheckCircleFill
        //             style={{
        //                 fontSize: 64,
        //                 color: 'var(--adm-color-success)',
        //             }}
        //         />
        //     ),
        //     closeOnMaskClick: true,
        //     content: <div>
        //         {`File (${fileName}) saved to your Downloads.`}
        //     </div>,
        //     confirmText: 'Open Now',
        //     cancelText: 'Okay',
        //     onConfirm: async () => {
        //         console.log("## Open Now ##");
        //         sendDataToApp({type: "openPdf", payload: filePath});
        //         // await deleteVoucher(voucherId);
        //         // await new Promise(resolve => setTimeout(resolve, 1500));
        //     },
        // })
    }

    const shareDocument = async ({channel, phone}) => {
        const title = "Invoice";
        const message = `Hi Shyam, Thank you for your business. Here is your tax invoice: ${publicLink} You can view or download PDF using this link.`;
        let filePath;
        if (channel === "whatsapp" && phone) {
            phone = `91${phone}`;
        }
        setShowWaModal(false);
        setShowSharePopup(false);
        if (channel === "whatsapp" || channel === "others") {
            const result = await appPermissionsHandler();
            if (!result) {
                return;
            }
            setLoading("Generating PDF to share");
            filePath = await downloadFile();
            setLoading("");
        }
        sendDataToApp({type: "shareDocument", payload: {channel, filePath, phone, message, title}});
    }

    return (
        <ActivityWrapper heading={"Preview Document"}
                         right={
                             <div style={{fontSize: 20, color: 'red'}}>
                                 <DeleteOutlined onClick={() => {
                                     Dialog.confirm({
                                         header: (
                                             <ExclamationCircleFill
                                                 style={{
                                                     fontSize: 64,
                                                     color: 'var(--adm-color-warning)',
                                                 }}
                                             />
                                         ),
                                         closeOnMaskClick: true,
                                         content: 'Do you really want to delete this document?',
                                         confirmText: 'Yes',
                                         cancelText: 'Cancel',
                                         onConfirm: async () => {
                                             await deleteVoucher(voucherId);
                                             await new Promise(resolve => setTimeout(resolve, 1500));
                                             goBack();
                                             // navigate(`/bills/${voucherType}`); --
                                         },
                                     })
                                 }}/>
                             </div>
                         }
        >
            <div>
            <Grid columns={3} style={{
                position: 'absolute',
                bottom: 0,
                height: 56,
                width: '100%',
                // border: '2px solid red',
                textAlign: 'center',
                verticalAlign: 'middle',
                // lineHeight: '48px',
                color: 'white',
                // fontWeight: 'bold',
                // fontSize: 22
            }}>
                <Grid.Item style={{padding: 0}} onClick={onClickPdf}>
                    <Button style={{width: '100%', height: '100%', borderRadius: 0}} color={"warning"}>
                        <DownlandOutline/>&nbsp;PDF
                    </Button>
                </Grid.Item>
                <Grid.Item style={{padding: 0}}>
                    {/*<Link to={`/vouchers/${voucherType}/edit/${voucherId}`}>*/}
                        <Button onClick={() => {
                            navigate(`/vouchers/${voucherType}/edit/${voucherId}`);
                        }} style={{width: '100%', height: '100%', borderRadius: 0}} color={"primary"}>
                            <EditSOutline/>&nbsp;Edit
                        </Button>
                    {/*</Link>*/}
                </Grid.Item>
                <Grid.Item style={{padding: 0}}>
                    <Button color={"success"} style={{width: '100%', height: '100%', borderRadius: 0}}
                            onClick={() => setShowSharePopup(true)}>
                        <ShareAltOutlined/>&nbsp;Share
                    </Button>
                </Grid.Item>
            </Grid>
            <div style={{
                textAlign: 'center',
                padding: 16,
                width: '100%',
                height: 'available',
                // border: '2px solid black',
                position: 'absolute',
                top: 56,
                bottom: 56
            }}>

                {
                    voucherId &&
                    <div style={{
                        width: '100%',
                        height: '100%',
                        border: '2px solid #888',
                        // overflow: 'scroll',
                        background: 'white',
                        padding: 2
                    }}>
                            <PreviewDocumentComponent previewPdfUrl={previewPdfUrl}/>
                    </div>

                }

                {/*{console.log("## RefWidth ##", previewRef?.current?.offsetWidth)}*/}

                {/*</center>*/}
            </div>

            <Popup
                visible={showSharePopup}
                onMaskClick={() => {
                    setShowSharePopup(false)
                }}
                bodyStyle={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8
                }}
            >
                <div style={{height: '50vh', overflow: 'scroll'}}>
                    <List>
                        <List.Item prefix={<WhatsAppOutlined/>} onClick={() => {
                            setShowSharePopup(false);
                            setShowWaModal(true);
                            waForm.setFieldsValue({
                                phone: voucher?.party?.phone || ""
                            });
                        }}>
                            Whatsapp
                        </List.Item>
                        <List.Item prefix={<MessageOutline/>} onClick={async () => {
                            const phone = voucher?.party?.phone;
                            if (phone && typeof phone === "string" && phone.length === 10) {
                                const resp = await sendSMS({
                                    origin: "EXTERNAL_KB_APP",
                                    phone: voucher?.party?.phone,
                                    smsTemplate: "KB_SEND_INVOICE",
                                    params: {
                                        customerName: voucher?.party?.name || "customer",
                                        merchant: state?.company?.name || "",
                                        invoiceNum,
                                        amount: `Rs. ${voucher?.amount || ""}`,
                                        url: publicLink,
                                    }
                                },"sms");

                                if (!resp.data.success) {
                                    message.error(resp.data.message);
                                } else {
                                    message.success("Document sent successfully!");
                                }
                            } else {
                                Dialog
                                    .alert({
                                        title: "Error",
                                        content: "Invalid mobile number in client details of invoice.",
                                        confirmText: "Okay"
                                    });
                            }
                        }}>
                            SMS
                        </List.Item>
                        <List.Item prefix={<MailOutlined/>} onClick={() => {
                            setShowSharePopup(false);
                            setShowEmailModal(true);
                            emailForm.setFieldsValue({
                                to: voucher?.party?.email,
                                subject: `Invoice ${invoiceNum} from ${state?.company?.name}`,
                                body: `Hi ${voucher?.party?.name},\nThank you for your business.\nPlease find the tax invoice ${invoiceNum} attached with this email.\nRegards,\n${state?.company?.name}`,
                            });
                        }}>
                            Email
                        </List.Item>
                        <List.Item prefix={<LinkOutlined/>} onClick={() => {
                            setShowSharePopup(false);
                            setShowCopied(false);
                            setShowLinkModal(true);
                        }}>
                            Public URL Link
                        </List.Item>
                    </List>
                </div>
            </Popup>
            <Mask opacity={"thick"} color={"white"}
                  style={{
                      width: '100%',
                      height: '100%',
                      // border: '4px solid green',
                      textAlign: 'center',
                      paddingTop: '40vh'
                  }}
                  visible={!!loading}>
                <div className={styles.overlayContent} style={{fontSize: 30, color: PRIMARY_COLOR}}>
                    <b>
                        <div style={{fontSize: 20}}>{loading || "Processing"}</div>
                        <Loading style={{color: 'currentcolor'}}/></b>
                </div>
            </Mask>
            <Modal
                visible={showWaModal}
                title={"Share using Whatsapp"}
                closeOnMaskClick={true}
                content={<div>
                    <Form form={waForm}>
                        <Form.Item name={"phone"} label={"Whatsapp Mobile Number"}
                                   rules={[{required: true, message: 'Invalid mobile number', len: 10}]}>
                            <Input type={"number"} max={9999999999} placeholder={"Enter 10 digit number"}
                                   style={{border: '1px solid #333', padding: 4, borderRadius: 4, width: '100%'}}/>
                        </Form.Item>
                    </Form>
                    <MobileButtonV2 style={{width: '100%'}} color={"primary"} onClick={async () => {
                        const {phone} = await waForm.validateFields();
                        console.log("## Phone ##", phone);
                        const party = voucherType === "receipt" ? voucher.crParty : voucher.party;
                        const resp = await sendSMS({
                            origin: "EXTERNAL_KB_APP",
                            phone: phone,
                            smsTemplate: "KB_WHATSAPP_SEND_VOUCHER",
                            params: {
                                voucherLink:"https://app.khatabuddy.com/api/internal/pdf/"+voucher?._id,
                                documentType:voucherTypes[voucherType]?.name || "Document",
                                customerName:party?.name || " ",
                                amount: `${RUPEE} ${voucher.amount}`,
                                url: voucher?.hashId,
                                action:`your purchase at ${state?.company?.name}`,
                                voucherFileName:"invoice.pdf"
                            }
                        },"whatsapp");
                        if (!resp.data.success) {
                            message.error(resp.data.message);
                        } else {
                            message.success("Document sent successfully!");
                        }
                        setShowWaModal(false);
                    }}>Share on this number</MobileButtonV2>
                </div>}
                onClose={() => setShowWaModal(false)}
                closeOnAction
            />
            <Modal
                style={{width: '100%'}}
                visible={showEmailModal}
                title={"Email Document"}
                closeOnMaskClick={true}
                content={<div style={{width: '100%'}}>
                    <Form form={emailForm} style={{padding: 0, margin: 0}}>
                        <Form.Item name={"to"} style={{width: '100%', padding: 0, margin: 0}}
                                   label={"Recipient email address"}
                                   rules={[{required: true, type: "email", message: 'Invalid email'}]}>
                            <Input placeholder={"Enter email aaddress"}
                                   style={{border: '1px solid #333', padding: 4, borderRadius: 4, width: '100%'}}/>
                        </Form.Item>
                        <Form.Item name={"subject"} label={"Email Subject"}
                                   rules={[{required: true, message: "can't be blank"}]}
                                   style={{width: '100%', padding: 0, margin: 0}}>
                            <Input placeholder={"Email Subject"}
                                   style={{border: '1px solid #333', padding: 4, borderRadius: 4, width: '100%'}}/>
                        </Form.Item>
                        <Form.Item
                            name={"body"}
                            label={"Email Body"}
                            rules={[{required: true, message: "can't be blank"}]}
                            style={{width: '100%', padding: 0, margin: 0}}
                        >
                            <TextArea style={{
                                border: '1px solid #333',
                                padding: 4,
                                borderRadius: 4,
                                width: '100%',
                                '--font-size': '14px'
                            }} rows={4}/>
                        </Form.Item>
                    </Form>
                    <p style={{color: PRIMARY_COLOR}}>
                        <i>
                            Invoice PDF will be automatically attached with email.
                        </i>
                    </p>
                    {/*<Alert type={"info"}*/}
                    {/*       description={"Invoice PDF will be automatically attached with email."}*/}
                    {/*/>*/}
                </div>}
                onClose={() => setShowEmailModal(false)}
                actions={[
                    {key: 'send', text: "Send Email", primary: true}
                ]}
                onAction={async (action) => {
                    console.log("## Action ##", action);

                    const formData = await emailForm.validateFields();
                    console.log("## formData ##", formData);

                    formData.from = "";
                    formData.voucherId = voucherId;
                    formData.filename = fileName;
                    await emailInvoice(formData);

                    await new Promise(resolve => setTimeout(resolve, 2500));
                    setShowEmailModal(false);

                    Dialog.alert({
                        title: "Email sent successfully",
                        header: (
                            <CheckCircleFill
                                style={{
                                    fontSize: 64,
                                    color: 'var(--adm-color-success)',
                                }}
                            />
                        ),
                        closeOnMaskClick: true,
                        confirmText: "Okay"
                    })
                }}
                closeOnAction
            />
            <Modal
                visible={showLinkModal}
                title={"Public URL Link"}
                closeOnMaskClick={true}
                content={<div>
                    <InputB
                        ref={(thisRef) => setRef(thisRef)}
                        value={publicLink}
                    />
                    <br/><br/>
                    <Alert type={"info"}
                           description={"This is a public URL. Anyone using this link can view or download this invoice. You can share it with your clients."}
                    />
                    <br/>
                    <MobileButtonV2 style={{width: '100%'}} color={"primary"} onClick={async (e) => {
                        ref.select();
                        document.execCommand('copy');
                        setShowCopied(true);
                        e.target.focus();
                    }}><CopyOutlined/>&nbsp;Copy to clipboard</MobileButtonV2>
                    {
                        showCopied &&
                        <p style={{width: '100%', color: 'green', textAlign: 'center', marginTop: 16}}>Copied !</p>
                    }
                </div>}
                onClose={() => setShowLinkModal(false)}
                closeOnAction
            />
            </div>
        </ActivityWrapper>
    )
};

export default React.memo(VoucherViewActivity);
