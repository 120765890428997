import React, { useState } from 'react';
import { DrawersWrapper } from "../drawers/DrawersWrapper";
import { SearchOutline } from "antd-mobile-icons";
import { Input, List } from "antd-mobile";
import Text from "../components/Text";
import styled from "styled-components";
import { logRender } from "../../utils";
import { useLayersFramework } from "../v2framework/framework.hooks";
import _ from "lodash";
import { EnumLayer } from "../v2framework/layers.list";

const minItemsCountForSearch = 8;

const SearchBar = ({ searchText, setSearchText, searchPlaceholder }) => {
    return <div style={{ padding: 6, background: "#fff" }}>
        <div
            style={{
                width: "100%",
                fontSize: 24,
                verticalAlign: "middle",
                lineHeight: "30px",
                borderRadius: 8,
                background: "#d0caca4a",
                display: "flex",
                flexDirection: "row",
                padding: 4,
                alignItems: "center",
            }}
        >
            <SearchOutline style={{ width: 30, color: "gray" }} />

            <Input
                value={searchText}
                onChange={(val) => setSearchText(val)}
                placeholder={searchPlaceholder}
                style={{
                    width: "100%",
                    fontSize: 17,
                    paddingLeft: 8,
                    border: "none",
                }}
                clearable
            />
        </div>
    </div>
}

export const BottomSelectPopupV2 = React.memo(({ }) => {
    logRender("BottomSelectPopupV2");
    const { args, goBack, currentLayers, params: layerParams } = useLayersFramework();
    const [searchText, setSearchText] = useState("");

    const { header, optionsList, searchPlaceholder, selectedValue: selectedOptionValue, OnSelect: onSelectOption, subLabel } = args || {};

    const forcedVisibility = !!_.keyBy(currentLayers, "target")[EnumLayer.GENERIC_SELECT_DRAWER]?.shouldDisplay;

    const filteredOptionsList = optionsList.filter((optionItem) => {
        if (!searchText) return true;
        return optionItem.label?.toLowerCase?.()?.includes?.(searchText.toLowerCase());
    });

    return <DrawersWrapper forcedVisibility={forcedVisibility}>
        {
            <>
                <PopUpHeader>
                    <TopLine />
                    {header}
                </PopUpHeader>
                {
                    optionsList.length > minItemsCountForSearch &&
                    <SearchBar searchText={searchText} setSearchText={setSearchText} searchPlaceholder={searchPlaceholder} />
                }
                <ListContainer>
                    <List>
                        {filteredOptionsList.map((optionItem) =>
                                <Item selected={optionItem.value === selectedOptionValue}>
                                    <List.Item arrow={false} onClick={() => {
                                        onSelectOption(optionItem.value);
                                        goBack();
                                    }}>
                                        <Text fontWeight={600} content={optionItem.label} />
                                        {subLabel && (
                                            <Text
                                                color="gray"
                                                fontWeight={600}
                                                content={subLabel + optionItem.subValue}
                                            />
                                        )}
                                    </List.Item>
                                </Item>
                            )}
                    </List>
                </ListContainer>
            </>
        }
    </DrawersWrapper>
});


const PopUpHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 12px 12px 0;
  font-size: 20px;
  font-weight: bold;
  border-top-right-radius: 10%;
  flex-direction: column;
`;

const TopLine = styled.div`
  height: 2.5px;
  background: lightgray;
  width: 52px;
  border-radius: 2px;
  margin-bottom: 10px;
`;

const ListContainer = styled.div`
  max-height: 40vh;
  overflow-y: scroll;
  margin-top: 5px;
  border-top: 2px solid #d0caca4a;
`;

const Item = styled.div`
  background: ${({ selected }) => selected && "#d0caca4a"};
`;

