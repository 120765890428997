import * as Sentry from "@sentry/react";

export const initSentry = () => {
  Sentry.init({
    dsn: "https://fc078c2e5468de7e54f906a1bd1ac774@o1006782.ingest.us.sentry.io/4508642210742272",
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
  });
  overrideConsoleError();
};


const overrideConsoleError = () => {
    const nativeConsoleError = console.error;
    console.error = (...args) => {
        Sentry.captureException(args);
        return nativeConsoleError(...args);
    };
};
