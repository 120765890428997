import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Activity from "./activity";
import {Button,NavBar, Input, Image, Form} from "antd-mobile";
import {customDispatcher} from "../utils";
import {loginRequest, saveCompany} from "../services/api";
import {getCredentials} from "../services/cookiesPlus";
import imgEnterprise from "../res/img/enterprise.png";
import ActivityWrapper from "./components/Activity";
import {useNavigate} from "react-router-dom";
import { MobileButtonV2 } from "./components/MobileButtonV2";


const LastStep = () => {
    const state = useSelector(state => state);
    const dispatch = customDispatcher(useDispatch());
    const navigate = useNavigate();
    const [companyName, setCompanyName] = useState("");
    const [res, setRes] = useState({});

    const handleSubmit = async () => {
        console.log("## Clicked ##");
        const trimmedCompanyName = companyName?.trim?.();
        if (!trimmedCompanyName) {
            setRes({
                type: "error",
                msg: "can't be blank"
            });
            return;
        } else {
            console.log("## Else ##");
            setRes({type: "success"});
        }
        await saveCompany({_id: state?.company?._id, name: trimmedCompanyName});
        const currCred = getCredentials();
        await loginRequest(currCred.username, currCred.password, dispatch);
        navigate("/dashboard");
    };

    return (
        <ActivityWrapper
            noHeading={true}
            content={
                <div>
                    <div style={{textAlign: 'center'}}>
                        <div style={{textAlign: 'center'}}>
                            <br/>
                            <Image src={imgEnterprise} style={{height: 130}}/>
                            <br/>
                            <h1><strong>
                                Last step before we start invoicing !
                            </strong></h1>
                            <p style={{color: 'grey'}}>
                                Please let us know your business name to start creating beautiful invoices
                            </p>
                        </div>

                        <div style={{padding: 16}}>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}>
                                <Input
                                    onChange={(val) => setCompanyName(val)}
                                    placeholder={"Business Name"}
                                    className='myTextInput'
                                    onEnterPress={handleSubmit}
                                />
                            </form>
                        </div>

                        <div style={{padding: '0 16px', marginTop: '20px'}}>
                            <MobileButtonV2 
                                style={{
                                    borderRadius: '8px', 
                                    width: '100%', 
                                    height: '44px'
                                }} 
                                color={"primary"}
                                disabled={res?.type === "success"}
                                onClick={handleSubmit}>
                                Start Invoicing
                            </MobileButtonV2>
                        </div>

                    </div>
                    {
                        res.type==="error" && !companyName?.trim?.() &&
                        <div style={{color: 'red', textAlign: 'center', marginTop: '10px'}}>
                            {res.msg}
                        </div>
                    }
                </div>
            }
        />
    )
};

export default LastStep;
