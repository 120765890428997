import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Form, Input, message } from "antd";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { loginRequest, stockInOut } from "../../../../services/api";
import { customDispatcher } from "../../../../utils";
import { CustomInput } from "../../../../styles/js/styleConstants";

const StockInOut = ({ closeDrawer, stock, stockIn }) => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const dispatch = customDispatcher(useDispatch());

  useEffect(() => {
    form.resetFields();
    if (stock) {
      form.setFieldsValue({
        name: stock.name,
        date: moment(stock.date),
      });
    } else {
      form.setFieldsValue({
        date: moment(),
      });
    }
  }, [stock, form]);

  return (
    <>
      <OuterContainer>
        <Header>
          <TopLine />
          {stockIn ? "Stock In" : "Stock Out"}
        </Header>
        <Form
          form={form}
          name="basic"
          wrapperCol={{ span: 16 }}
          onFinish={async (data) => {
            setSaving(true);
            const response = await stockInOut({
              itemId: stock._id,
              qty: stockIn ? data.qty : -data.qty,
              remarks: data.remarks,
              date: new Date(),
            });
            await loginRequest("", "", dispatch);
            if (!response.data.success) {
              message.error(response.data.message);
              setSaving(false);
              return;
            }

            message.success("Inventory Record Saved");
            setSaving(false);
            closeDrawer();
            form.resetFields();
            form.setFieldsValue({
              date: moment(),
            });
          }}
          onFinishFailed={(err) => {
            console.error("## Finish Failed ##", err);
          }}
          autoComplete="off"
        >
          <Form.Item
            labelCol={{
              span: 12,
              style: {
                textAlign: "left",
              },
            }}
            name={"name"}
            label={<p style={{ fontSize: "16px", marginTop: "20px" }}>Name</p>}
          >
            <CustomInput disabled={true} value={stock.name} />
          </Form.Item>

          <Form.Item
            name="qty"
            label={<p style={{ fontSize: "16px", marginTop: "20px" }}>Qty</p>}
            rules={[
              {
                required: true,
                message: "Quantity cannot be less than 1",
              },
            ]}
          >
            <CustomInput type="number" min={1} max={100000000} />
          </Form.Item>

          <Form.Item
            name={"remarks"}
            label={
              <p style={{ fontSize: "16px", marginTop: "20px" }}>Remarks</p>
            }
            rules={[
              {
                required: true,
                message: "Maximum 100 words",
                max: 100,
              },
            ]}
          >
            <CustomInput rows={2} placeholder={"Write remark"} />
          </Form.Item>

          <ButtonContainer>
            <Button
              type="primary"
              htmlType="submit"
              loading={saving}
              style={{
                width: "100%",
                fontSize: "18px",
                height: "40px",
                background: "#0080FF",
              }}
            >
              {stockIn ? "Add Stock" : "Remove Stock"}
            </Button>
          </ButtonContainer>
        </Form>
      </OuterContainer>
    </>
  );
};

export default StockInOut;

const OuterContainer = styled.div`
  padding: 5% 7% 15%;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 2px solid darkgray;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  width: 100%;
  bottom: -2px;
  position: absolute;
  margin-left: -7%;
`;

const TopLine = styled.div`
  height: 2.5px;
  background: lightgray;
  width: 52px;
  border-radius: 2px;
  margin-bottom: 10px;
`;
