import React, { useRef } from "react";
import styled from "styled-components";
import { DownOutlined } from "@ant-design/icons";
import { truncate } from "../../utils";
import {useLayersFramework} from "../v2framework/framework.hooks";
import {ELayerTrigger, ELayerType} from "../v2framework/framework.hooks";
import {EnumLayer} from "../v2framework/layers.list";

const BottomSelectFormComponent = ({
  placeholder,
  searchPlaceholder,
  optionsList,
  header,
  value,
  onChange,
  disabled,
  subLabel,
}) => {
  const { pushInAppLayer } = useLayersFramework();
  const dropdownRef = useRef();

  const OnSelect = (selectedValue) => {
    onChange(selectedValue);  
  };

  const optionLabel = truncate(optionsList.find(option => option.value === value)?.label || "", 25);

  return (
      <DropdownContainer
        ref={dropdownRef}
        onClick={
          () => {
            pushInAppLayer({
              trigger: ELayerTrigger.IN_APP,
              type: ELayerType.FRAGMENT,
              target: EnumLayer.GENERIC_SELECT_DRAWER,
            }, {
              // selectedItemName, setItemName, setAddItemModal, zIndex: Z_INDEXES.ITEMS_LIST_BOTTOM_DRAWER,
              header, optionsList, searchPlaceholder, selectedValue: value, OnSelect, subLabel,
            });
          }
        }
        disabled={disabled || false}
      >
        <ValueContainer>{value ? optionLabel : placeholder}</ValueContainer>
        <DownOutlined style={{ color: "black" }} />
      </DropdownContainer>
  );
};

export default BottomSelectFormComponent;

const DropdownContainer = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid black;
  background: ${console.log("SD")}
  justify-content: space-between;
  padding: 4px 9px;
  align-items: center;
  height: 35px;
  justify-content: space-between;
  border-radius: 10px;
  pointer-events: ${({ disabled }) => disabled && "none"};
  background-color: ${({ disabled }) => disabled && "#d0caca4a"};
`;

const ValueContainer = styled.div`
  font-size: 18px;
`;



