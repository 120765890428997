import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { Button, message, Tag } from "antd";
import { Modal } from "antd-mobile";
import {
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Activity from "../components/Activity";
import Text from "../components/Text";
import { RUPEE } from "../../models/constants";
import { beautifyAmount } from "../../utils";
import { createEcommerceOrder } from "../../services/api";
import { ButtonV2 } from "../components/ButtonV2";

const Order = ({ orderData, onCancel, onSave }) => {
  const [orderStatus, setOrderStatus] = useState();
  const [visible, setVisible] = useState(false);

  const updateProductStatus = async (value) => {
    try {
      if (!value) {
        return;
      }
      orderData.status = value;

      await createEcommerceOrder(orderData);
    } catch (e) {
      console.error(e, "Error");
    }
  };

  useEffect(() => {
    setOrderStatus(orderData?.status);
  }, [orderData]);

  const onChange = (e) => {
    setOrderStatus(e);
    updateProductStatus(e);
  };

  const OrderStatus = {
    pending: {
      icon: <ClockCircleOutlined />,
      color: "warning",
    },
    cancelled: {
      icon: <CloseCircleOutlined />,
      color: "error",
    },
    completed: {
      icon: <CheckCircleOutlined />,
      color: "success",
    },
  };

  const OrderCard = (record, sno) => {
    console.log(record);
    return (
      <CardContainer>
        <InnerContainer width="60%">
          <Text fontSize={20} fontWeight={600} content={record?.name} />
          <Text fontSize={18} content={record.defaultRate} />
        </InnerContainer>
        <InnerContainer width="40%" flexEnd>
          <Text
            fontSize={18}
            color="green"
            fontWeight={600}
            content={RUPEE + beautifyAmount(record.amount || 0)}
          />
          <Text fontSize={18} content={record.qty} />
        </InnerContainer>
      </CardContainer>
    );
  };

  return (
    <Activity
      heading="Details"
      onBack={() => {
        onCancel();
      }}
      content={
        <><MainContainer>
          <Text
            fontSize={20}
            fontWeight={600}
            content="Customer Details" />
          <Container>
            <InnerContainer>
              <Text
                fontSize={18}
                fontWeight={600}
                content={orderData?.customerDetails?.name} />
              <Text color="grey" fontSize={18} content={orderData?.customerDetails?.phone} />
              <Text
                fontSize={18}
                fontWeight={600}
                color="gray"
                content={moment(orderData.createdDate).format(
                  "DD MMM YYYY hh:mm A"
                )} />
            </InnerContainer>
            <TagContainer>
              <Tag
                color={OrderStatus[orderStatus]?.color}
                icon={OrderStatus[orderStatus]?.icon}
                style={{
                  height: '35px',
                  fontSize: '17px',
                  padding: '6px'
                }}
              >
                {orderStatus
                  ? orderStatus.charAt(0).toUpperCase() + orderStatus.slice(1)
                  : ""}
              </Tag>
            </TagContainer>
          </Container>

          <OuterContainer>
            <Text fontSize={20} fontWeight={600} content="Items" />
            <Container column gap="20">
              {orderData.items.map((i, index) => OrderCard(i, index))}
            </Container>
            <TopContainer end>
              <h2 style={{ color: "green" }}>
                Total Amount: ₹{orderData.amount}{" "}
              </h2>
            </TopContainer>
          </OuterContainer>
          {orderStatus === "pending" && (
            <ButtonContainer>
              <Button
                style={{
                  width: "51%",
                  height: "50px",
                  fontSize: "22px",
                  background: "green",
                  border: "1px solid green",
                }}
                type={"primary"}
                onClick={(e) => {
                  onChange("completed");
                  message.success("Marked as Completed");
                  onSave();
                } }
              >
                Mark Completed
              </Button>
              <Button
                style={{
                  width: "51%",
                  height: "50px",
                  fontSize: "22px",
                  background: "red",
                  border: "1px solid red",
                }}
                type={"primary"}
                onClick={() => {
                  setVisible(true);
                  onSave();
                } }
              >
                Cancel order
              </Button>
            </ButtonContainer>
          )}

          <Modal
            visible={visible}
            title="Cancel Order"
            closeOnMaskClick={true}
            content={<>
              <Text
                fontSize={18}
                fontWeight={600}
                content="Are you sure to cancel this order ?" />
              <BottomContainer>
                <ButtonV2
                  style={{
                    width: "40%",
                    height: "40px",
                    fontSize: "18px",
                    background: "red",
                  }}
                  type={"primary"}
                  onClick={async (e) => {
                    e.stopPropagation();
                    onChange("cancelled");
                    message.success("Successfully order has been cancelled");
                    setVisible(false);
                  } }
                >
                  Yes
                </ButtonV2>
                <Button
                  style={{
                    width: "40%",
                    height: "40px",
                    fontSize: "18px",
                    background: "green",
                  }}
                  type={"primary"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setVisible(false);
                  } }
                >
                  No
                </Button>
              </BottomContainer>
            </>}
            onClose={() => setVisible(false)}
            closeOnAction />
        </MainContainer>
      </>
      }
    />
  );
};

export default Order;

const MainContainer = styled.div`
  padding: 15px;
`;

const OuterContainer = styled.div`
  height: 550px;
  margin-top: 15px;
`;

const TopContainer = styled.div`
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: ${({ end }) => end && "flex-end"};
`;

const BottomContainer = styled.div`
  display: flex;
  margin-top: 15px;
  width: 100%;
  justify-content: space-around;
`;

const CardContainer = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 2px solid lightgray;
  gap: 10px;
  align-items: center;
`;

const InnerContainer = styled.div`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  align-items: ${({ flexEnd }) => flexEnd && "flex-end"};
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  width: 100%;
  bottom: -2px;
  margin-left: -15px;
  position: absolute;
  display: flex;
`;

const Container = styled.div`
  height: ${(height) => `${height}px`};
  border-radius: 12px;
  padding: 15px;
  display: flex;
  margin-top: 15px;
  width: 100%;
  box-shadow: 0px 0px 12px -3px rgba(97,94,97,1);
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  margin-top: ${({ top }) => `${top}px`};
  gap: ${({ gap }) => `${gap}px`};
  align-items: ${({ center }) => center && "center"};
  justify-content: space-between;
  max-height: 440px;
  margin-bottom: 15px;
}
`;

const TagContainer = styled.div`
`;