/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useMemo} from "react";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { Loading, Button } from "antd-mobile";
import { Input, Tag, Tabs } from "antd";
import { SearchOutline, AddCircleOutline } from "antd-mobile-icons";
import {
  MoneyCollectOutlined,
  CarOutlined,
  FileTextOutlined,
  FileSyncOutlined,
  FileOutlined,
  UpCircleFilled,
  DownCircleFilled,
  EditFilled,
  ArrowRightOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  AntCloudOutlined,
} from "@ant-design/icons";

import { RUPEE } from "../../../../models/constants";
import {beautifyAmount, logRender} from "../../../../utils";
import { getVouchers } from "../../../../services/api";

import DateRangePicker from "../../../components/DateRangePicker";
import Activity from "../../../components/Activity";
import Text from "../../../components/Text";
import {EmptyComponent} from "../../../components/EmptyComponent";
import {useMyParams} from "../../../v2framework/framework.hooks";
import {dateRangeTemplatesMap} from "../../dateRangePicker/rangeTemplates";
import {useDateRange} from "../../../../hooks/dateRange.hook";
import {nonPreviewVoucherTypes} from "../../../../models/jsConstants";
import CustomFAB from "../../../components/CustomFAB";


const MenuItems = [
  {
    title: "Invoices",
    active: "invoice",
  },
  {
    title: "Quotations",
    active: "proforma",
  },
  {
    title: "Purchases",
    active: "purchase",
  },
  {
    title: "Credit Note",
    active: "creditNote",
  },
  {
    title: "Debit Note",
    active: "debitNote",
  },
  {
    title: "Delivery Challan",
    active: "delivery",
  },
  {
    title: "Payments",
    active: "payment",
  },
];


const OrderCard = React.memo(({record}) => {
  logRender("OrderCard");
  const balance = record.amount - (record.paid || 0);

  return (
      <CardContainer
          className={"greyLightHoverEffect"}
      >
        <OrderInnerContainer space>
          <Text fontSize={14} fontWeight={600} content={record?.partyName} />
          <Text
              fontSize={14}
              color="green"
              fontWeight={600}
              content={RUPEE + beautifyAmount(record.amount || 0)}
          />
        </OrderInnerContainer>
        <OrderInnerContainer space>
          <Text
              fontSize={12}
              color="grey"
              content={record.displayDocNum}
          />
          <span style={{ marginRight: "-8px" }}>
            <Tag
                color={
                  balance === record.amount
                      ? "red"
                      : balance > 0
                          ? "orange"
                          : balance < 0
                              ? "cyan"
                              : "green"
                }
                style={{ fontSize: "10px", padding: "0px 2px" }}
            >
              {balance === record.amount
                  ? "Pending"
                  : balance > 0
                      ? "Partially Paid"
                      : balance < 0
                          ? "Over Paid"
                          : "Paid"}
            </Tag>
          </span>
        </OrderInnerContainer>
        <Text
            fontSize={12}
            color="grey"
            content={moment(record.date).format("DD MMM YYYY")}
        />
      </CardContainer>
  );
});



const VouchersTab = () => {
  logRender("VouchersTab");
  // const {startDate, endDate, filterType } = getAppDateRange();
  const [selectedDateRange, , actualValues] = useDateRange();
  const startDate = actualValues.start;
  const endDate = actualValues.end;
  const filterType = selectedDateRange.filterType;

  console.log("## BillsData ##", filterType);
  // const {financialStartDate, financialEndDate} = getCurrentFinancialYear();
  // const yearRangeLabel = `${"FY " + moment(financialStartDate).format('YY') + "-" + moment(financialEndDate).format('YY')}`;

  const [a, params] = useMyParams();
  const navigate = useNavigate();
  console.log("## VouchersTab params ##", params);
  const billsType = params.voucherType || "invoice";
  const { TabPane } = Tabs;

  const activeTab = billsType;
  // const [activeTab, setActiveTab] = useState(billsType || "invoice");
  const [searchText, setSearchText] = useState("");
  const [openDetails, setOpenDetails] = useState(false);
  const [records, setRecords] = useState();
  const [activeFilter, setActiveFilter] = useState("all");
  const [showDateModal, setShowDateModal] = useState(false);

  const {filteredList, total, paid, pending} = useMemo(() => {
    // find filtered list + calculate total / pending amounts

    const filteredList = (records || []).sort((a, b) => moment(b.date) - moment(a.date)).filter((data) => {
      // Filters: Date, SearchText, paymentStatus
      let filterByDate = true;
      let filterByText = true;
      let filterByPaymentStatus = true;
      // SearchText Filter:
      filterByText = (data.partyName?.toLowerCase() || "").includes(searchText?.toLowerCase() || "");

      // Date Filter
      if (startDate && endDate) {
        filterByDate = moment(data.date).isBetween(
           startDate && startDate.startOf("day"),
           endDate && endDate.endOf("day")
        );
      }

      // PaymentStatus Filter:
      filterByPaymentStatus = activeFilter === "all" || (
          activeFilter === "pending" ? (data.amount - (data.paid || 0) > 0)
              : (data.amount - (data.paid || 0) <= 0)
      );

      return filterByDate && filterByText && filterByPaymentStatus;
    });

    let total = 0,
        paid = 0;
    filteredList.forEach((r) => {
      total += r.amount || 0;
    });

    const pending = total - paid;

    return {
      filteredList,
      total,
      paid,
      pending,
    }
  }, [records, activeFilter, searchText, startDate, endDate]);

  useEffect(() => {
    console.log("## getting vouchers data ##");
    const getData = async () => {
      try {
        const {
          data: {
            data: { vouchers: data },
          },
        } = await getVouchers(billsType=== "payment" ? "receipt,payment" : (billsType || "invoice"));
        setRecords(data);
      } catch (e) {
        console.error(e, "Error in getting orders");
      }
    };
    getData();
  }, [billsType]);

  useEffect(() => {
    setActiveFilter("all");
    setOpenDetails(false);
  }, [activeTab]);


  return (
    <>
      <Activity
        heading="Bills"
        noHeading
        content={
          <div>
            {
              !!filteredList.length && (billsType !== "payment") &&
              <div onClick={() => {
                navigate(`/vouchers/${billsType}/create`);
              }}>
                <CustomFAB title={"Create"} Bottom={true} />
              </div>
            }

            <HeaderMenu>
              <Tabs
                className={"tabsLedgers"}
                activeKey={billsType}
                onChange={(newBillsType) => {
                  // setActiveTab(newBillsType);
                  navigate(`/vouchers/${newBillsType}`);
                }}
              >
                {MenuItems.map((i) => (
                  <TabPane
                    className={"primaryLightHoverEffect"}
                    tab={
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          margin: "0px 10px",
                        }}
                      >
                        {i.title}
                      </div>
                    }
                    key={i.active}
                  ></TabPane>
                ))}
              </Tabs>
            </HeaderMenu>
            <OuterContainer>
              {activeTab !== "More" && (
                <><Header>
                  <Container noGap shadow column position top="-10" background="white">
                    <Container center padding="1px">
                      <div style={{ marginBottom: "5px", width: "90%" }}>
                        <div
                          style={{
                            width: "100%",
                            fontSize: 16,
                            verticalAlign: "middle",
                            lineHeight: "20px",
                            borderRadius: 8,
                            background: "#d0caca4a",
                            display: "flex",
                            flexDirection: "row",
                            padding: 4,
                            alignItems: "center",
                          }}
                        >
                          <SearchOutline
                            style={{ width: 30, color: "grayō" }} />
                          <Input
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            placeholder="Search"
                            style={{
                              width: "100%",
                              fontSize: 16,
                              paddingLeft: 8,
                              border: "none",
                              background: "#f4f4f44a",
                            }}
                            clearable />
                        </div>
                      </div>
                      {!openDetails && (
                        <DownCircleFilled
                          style={{ color: "#0080ff", fontSize: 22 }}
                          onClick={() => setOpenDetails(true)} />
                      )}
                      {openDetails && (
                        <UpCircleFilled
                          style={{ color: "#0080ff", fontSize: 22 }}
                          onClick={() => setOpenDetails(false)} />
                      )}
                      {/* <FilterFilled style={{ color: "#0080ff", fontSize: 22 }} /> */}
                    </Container>
                    {!openDetails && activeTab === "invoice" && (
                      <InlineContainer>
                        <Text
                          color="grey"
                          fontSize={12}
                          fontWeight={600}
                          content={startDate && startDate.format("DD MMM YYYY")} />
                        <ArrowRightOutlined style={{ color: "grey", fontSize: "10px" }} />
                        <Text
                          color="grey"
                          fontSize={12}
                          fontWeight={600}
                          content={endDate && endDate.format("DD MMM YYYY")} />
                      </InlineContainer>
                    )}
                    {openDetails && (
                      <DetailsContainer>
                        <DetailsInnerContent>
                          <InnerContainer>
                            <InlineContainer>
                              <Text
                                fontSize={14}
                                fontWeight={600}
                                content={dateRangeTemplatesMap[filterType]?.label || dateRangeTemplatesMap[filterType]?.labelCreator?.() || "--"} />
                              <EditFilled
                                onClick={() => setShowDateModal(true)} />
                            </InlineContainer>
                            <InlineContainer>
                        <Text
                          color="grey"
                          fontSize={12}
                          fontWeight={600}
                          content={startDate && startDate.format("DD MMM YYYY")} />
                        <ArrowRightOutlined style={{ color: "grey", fontSize: "10px" }} />
                        <Text
                          color="grey"
                          fontSize={12}
                          fontWeight={600}
                          content={endDate && endDate.format("DD MMM YYYY")} />
                      </InlineContainer>
                          </InnerContainer>
                          {activeTab === "invoice" && (
                            <InnerContainer width="30%">
                              <InlineContainer>
                                <MoneyCollectOutlined style={{ color: "#108ee9" }} />
                                <Text
                                  fontSize={12}
                                  fontWeight={600}
                                  content="Total"
                                  color="#108ee9" />
                              </InlineContainer>
                              <Text
                                fontSize={14}
                                fontWeight={600}
                                content={`${RUPEE}${total}`}
                                color="#108ee9" />
                            </InnerContainer>
                          )}
                        </DetailsInnerContent>
                        {activeTab === "invoice" && (
                          <DetailsInnerContent>
                            <InnerContainer>
                              <InlineContainer style={{ color: "#ff5500" }}>
                                <ClockCircleOutlined />
                                <Text
                                  fontSize={12}
                                  fontWeight={600}
                                  content="Pending"
                                  color="#ff5500" />
                              </InlineContainer>
                              <Text
                                fontSize={14}
                                fontWeight={600}
                                content={`${RUPEE}${pending}`}
                                color="#ff5500" />
                            </InnerContainer>
                            <InnerContainer width="30%">
                              <InlineContainer>
                                <CheckCircleOutlined style={{ color: "#5c9444" }} />
                                <Text
                                  fontSize={12}
                                  fontWeight={600}
                                  content="Paid"
                                  color="#5c9444" />
                              </InlineContainer>
                              <Text
                                fontSize={14}
                                fontWeight={600}
                                content={`${RUPEE}${paid}`}
                                color="#5c9444" />
                            </InnerContainer>
                          </DetailsInnerContent>
                        )}
                      </DetailsContainer>
                    )}
                  </Container>
                  <Container padding="0px">
                    {activeTab === "invoice" && (
                      <StatusFilter>
                        <FilterOption
                          active={activeFilter === "all"}
                          onClick={() => setActiveFilter("all")}
                        >
                          <Text
                            fontSize={14}
                            fontWeight={600}
                            content="All"
                            color="white" />
                        </FilterOption>
                        <FilterOption
                          active={activeFilter === "paid"}
                          onClick={() => setActiveFilter("paid")}
                        >
                          <Text
                            fontSize={14}
                            fontWeight={600}
                            content="Paid"
                            color="white" />
                        </FilterOption>
                        <FilterOption
                          active={activeFilter === "pending"}
                          onClick={() => setActiveFilter("pending")}
                        >
                          <Text
                            fontSize={14}
                            fontWeight={600}
                            content="Pending"
                            color="white" />
                        </FilterOption>
                      </StatusFilter>
                    )}
                  </Container>
                </Header>
                <ListContainer
                  top={activeTab === "invoice" && openDetails
                    ? "280px"
                    : activeTab !== "invoice" && openDetails
                      ? "176px"
                      : activeTab === "invoice"
                        ? "200px"
                        : "135px"}
                >
                    {!records ? (
                      <Loading
                        style={{
                          padding: "37%",
                          fontSize: "40px",
                          color: "#0080ff",
                        }} />
                    ) : (
                      filteredList.length === 0 ? 
                    <EmptyComponent
                        message={"No documents found for selected date range"}
                        extras={
                          billsType !== "payment" &&
                          <Button
                              color={"primary"}
                              style={{marginTop: 8, borderRadius: 100}}
                              onClick={() => {
                                // () =>
                                navigate(`/vouchers/${billsType}/create`);
                              }}
                          >
                            <AddCircleOutline/>
                            &nbsp;Create New
                          </Button>
                        }
                    />
                      : filteredList.map((data) =>
                          <div onClick={() => {
                            console.log("## Voucher Clicked ##", data);
                            // pushActivity(EActivity.VOUCHER_VIEW, false);
                            if (nonPreviewVoucherTypes.includes(data.type)) {
                              navigate(`/vouchers/${billsType}/edit/${data._id}`);
                            } else {
                              navigate(`/vouchers/${billsType}/view/${data._id}`);
                            }
                          }}>
                              <OrderCard record={data} />
                          </div>
                          )
                    )}
                  </ListContainer></>
              )}
              {activeTab === "More" && (
                <>
                  <Container top>
                    <IconContainer width="25%">
                      <MoneyCollectOutlined
                        style={{ color: "#0080ff", fontSize: 25 }}
                      />
                      <Text fontSize={14} fontWeight={500} content="Expenses" />
                    </IconContainer>
                    <IconContainer width="25%">
                      <FileSyncOutlined
                        style={{ color: "#0080ff", fontSize: 25 }}
                      />
                      <Text
                        fontSize={14}
                        fontWeight={500}
                        textAlign="center"
                        content="Credit Notes"
                      />
                    </IconContainer>
                    <IconContainer width="25%">
                      <FileOutlined
                        style={{ color: "#0080ff", fontSize: 25 }}
                      />
                      <Text
                        fontSize={14}
                        fontWeight={500}
                        textAlign="center"
                        content="Purchase Orders"
                      />
                    </IconContainer>
                    <IconContainer width="25%">
                      <FileSyncOutlined
                        style={{ color: "#0080ff", fontSize: 25 }}
                      />
                      <Text
                        fontSize={14}
                        fontWeight={500}
                        textAlign="center"
                        content="Debit Notes"
                      />
                    </IconContainer>
                  </Container>
                  <Container>
                    <IconContainer width="25%">
                      <FileTextOutlined
                        style={{ color: "#0080ff", fontSize: 25 }}
                      />
                      <Text
                        fontSize={14}
                        fontWeight={500}
                        textAlign="center"
                        content="Proforma Invoices"
                      />
                    </IconContainer>
                    <IconContainer width="25%">
                      <MoneyCollectOutlined
                        style={{ color: "#0080ff", fontSize: 25 }}
                      />
                      <Text
                        fontSize={14}
                        fontWeight={500}
                        textAlign="center"
                        content="Eway Bills"
                      />
                    </IconContainer>
                    <IconContainer width="25%">
                      <MoneyCollectOutlined
                        style={{ color: "#0080ff", fontSize: 25 }}
                      />
                      <Text fontSize={14} fontWeight={500} content="Payments" />
                    </IconContainer>
                    <IconContainer width="25%">
                      <CarOutlined style={{ color: "#0080ff", fontSize: 25 }} />
                      <Text
                        fontSize={14}
                        fontWeight={500}
                        textAlign="center"
                        content="Delivery Challans"
                      />
                    </IconContainer>
                  </Container>
                </>
              )}
            </OuterContainer>
          </div>
        }
      />
      {/* <StackActivity
        visible={showOrder}
        setVisible={setShowOrder}
        type="order"
        data={singleOrder}
        onSave={onUpdateStatus}
      /> */}
      {showDateModal && (
        <DateRangePicker
          onClose={() => setShowDateModal(false)}
          filterType={filterType}
        />
      )}
    </>
  );
};

export default React.memo(VouchersTab);

const OuterContainer = styled.div`
  padding: 2%;
`;

const HeaderMenu = styled.div`
  display: flex;
  width: 100%;
  height: 66px;
      position: absolute;
    background: white;
  overflow: scroll;
  gap: 15px;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  . {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

const Container = styled.div`
  height: ${(height) => `${height}px`};
  border-radius: 12px;
  padding: ${({ padding }) => (padding ? padding : "10px")};
  display: flex;
  width: 100%;
  box-shadow: ${({ shadow }) => shadow && "0px 0px 12px -3px rgba(97,94,97,1)"};
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  margin-top: ${({ top }) => `${top}px`};
  gap: ${({ noGap }) => !noGap && "20px"};
  align-items: ${({ center }) => center && "center"};
  background: ${({background}) => background};
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InnerContainer = styled.div`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
}
`;

const InlineContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;

const DetailsInnerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Header = styled.div`
display: flex;
position: absolute;
background: white;
flex-direction: column;
width: 96%;
margin-top: 67px;
`;

const StatusFilter = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px 0px;
  margin: 6px;
  width: 100%;
  border-bottom: 1px solid #cac3ca;
`;

const FilterOption = styled.div`
  background: ${({ active }) => (active ? "#0080ff" : "grey")};
  border-radius: 10px;
  height: 25px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 12px;
  flex-direction: column;
  border: 2px solid lightgray;
  margin: 0px 0px 15px;
  padding: 5px 15px;
  box-shadow: 0px 0px 12px -3px rgba(97, 94, 97, 1);
`;

const ListContainer = styled.div`
  height: ${({ height }) => height};
  overflow: auto;
  margin-top: ${({ top }) => top};
  padding: 5px;
  width: 100%;
`;

const OrderInnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${({ space }) => space && "space-between"};
  gap: 12px;
  align-items: center;
`;

const IconContainer = styled.div`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  align-items: ${({ notCenter }) => (notCenter ? "" : "center")};
`;

