import {Popup} from "antd-mobile";
import React from "react";
import {logRender} from "../../utils";
import {useTransitionedVisibility} from "../../hooks/generic";
import {useLayersFramework} from "../v2framework/framework.hooks";


export const DrawersWrapper = React.memo(({popupStyles, children, forcedVisibility}) => {
    logRender("DrawerWrapper", !!forcedVisibility);
    const visibility = forcedVisibility;
    const [existence, actualVisibility] = useTransitionedVisibility(visibility);
    const {goBack} = useLayersFramework();

    return !existence ? <div></div> :
        <div>
            <Popup
                visible={actualVisibility}
                onMaskClick={() => {
                    goBack();
                }}
                bodyStyle={{
                    width: "100%",
                    borderTopRightRadius: "5%",
                    height: "450px",
                    borderTopLeftRadius: "5%",
                    ...(popupStyles || {})
                }}
            >
                {children}
            </Popup>
        </div>;
});