import React from "react";
import {Z_INDEXES} from "../../../../styles/js/z_indexes";
import Text from "../../../components/Text";
import {DownOutlined} from "@ant-design/icons";
import styled from "styled-components";
import {ELayerTrigger, ELayerType, useLayersFramework} from "../../../v2framework/framework.hooks";
import {EnumLayer} from "../../../v2framework/layers.list";


export const SelectItemInput = React.memo(({selectedItemName, setItemName, setAddItemModal, value, onChange}) => {
    const {pushInAppLayer} = useLayersFramework();

    return <>
        <SelectedContentView onClick={() => {
            pushInAppLayer({
                trigger: ELayerTrigger.IN_APP,
                type: ELayerType.FRAGMENT,
                target: EnumLayer.SELECT_ITEM_DRAWER,
            }, {
                selectedItemName, setItemName, setAddItemModal, zIndex: Z_INDEXES.ITEMS_LIST_BOTTOM_DRAWER
            });
        }}>
            {selectedItemName ? (
                selectedItemName
            ) : (
                <Text color="gray" fontSize={18} content="Select Item" />
            )}
        </SelectedContentView>
        <DownOutlined
            style={{
                color: "gray",
                position: "absolute",
                marginTop: "-6%",
                marginLeft: "92%",
            }}
        />
    </>
});


const SelectedContentView = styled.div`
  height: 40px;
  padding: 2px 12px;
  border-radius: 12px;
  border: 2px solid #d0caca4a;
  font-size: 18px;
  display: flex;
  align-items: center;
`;
