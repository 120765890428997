import React, { useState, useEffect } from "react";
import styled from "styled-components";
import _ from "lodash";

import {
  getPublicShareLink,
  copyToClipboard,
} from "../../utils";

import { Input, message, Tooltip, Button } from "antd";

import { CopyOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux";

import { fetchEcommerceSummary } from "../../services/api";

import Activity from "../components/Activity";
import Text from "../components/Text";
import StackActivity from "../components/StackActivity";

const EcommerceStore = () => {
  const state = useSelector((state) => state);
  const [storeInfo, setStoreInfo] = useState();
  const [showOrders, setShowOrders] = useState(false);

  useEffect(() => {
    getSummary();
  }, []);

  const publicLink = getPublicShareLink(
    state?.company?.hashId
  );

  const onCopyButtonClick = () => {
    copyToClipboard(publicLink);
    message.success("Link Copied");
  };

  async function getSummary() {
    try {
      const response = await fetchEcommerceSummary();
      if (!_.get(response.data, "success", false)) {
        throw new Error("Error in Fetching Summary");
      }

      let storeInfo = response?.data?.data || {};
      setStoreInfo(storeInfo);
    } catch (e) {
      console.error("Error in Fetching Summary details", e);
    }
  }

  return (
    <>
      <Activity
        heading="Ecommerce Store"
        content={
          <OuterContainer>
            <h2>
              <span style={{ color: "green" }}>
                <strong>KhataBuddy</strong>
              </span>{" "}
              is the best way for e-commerce companies to get more customers,
              increase AOV and LTV.
            </h2>{" "}
            <h2>
              What is an <strong>Ecommerce Store?</strong>
            </h2>
            <Text
              fontSize={18}
              content="E-commerce (electronic commerce) is the buying and selling of goods and services, or the transmitting of funds or data, over an electronic network, primarily the internet."
            />
            <StoreLink>
              <h2>Store Link </h2>
              <Input
                style={{ width: "calc(100% - 50px)", color: "#0080ff" }}
                value={publicLink}
                contentEditable={false}
              />
              <Tooltip title="Copy Store Link">
                <Button
                  icon={<CopyOutlined />}
                  style={{ paddingTop: "4px" }}
                  onClick={() => onCopyButtonClick()}
                />
              </Tooltip>
            </StoreLink>
            <StatsContainer>
              <Stats>
                <Text
                  color="darkGray"
                  fontSize={16}
                  fontWeight={600}
                  content="Store Views Count"
                />
                <Text
                  color="green"
                  fontSize={16}
                  fontWeight={600}
                  content={"₹ " + (storeInfo?.storeViewsCount || 0)}
                />
              </Stats>
              <Stats>
                <Text
                  color="darkGray"
                  fontSize={16}
                  fontWeight={600}
                  content="Total Orders Count"
                />
                <Text
                  color="green"
                  fontSize={16}
                  fontWeight={600}
                  content={"₹ " + (storeInfo?.totalOrdersCount || 0)}
                />
              </Stats>
            </StatsContainer>
            <StatsContainer>
              <Stats>
                <Text
                  color="darkGray"
                  fontSize={16}
                  fontWeight={600}
                  content="Total Orders Value"
                />
                <Text
                  color="green"
                  fontSize={16}
                  fontWeight={600}
                  content={"₹ " + (storeInfo?.totalOrdersValue || 0)}
                />
              </Stats>
              <Stats>
                <Text
                  color="darkGray"
                  fontSize={16}
                  fontWeight={600}
                  content="Total Pending Orders"
                />
                <Text
                  color="green"
                  fontSize={16}
                  fontWeight={600}
                  content={"₹ " + (storeInfo?.totalPendingOrdersCount || 0)}
                />
              </Stats>
            </StatsContainer>
            <Button
              style={{
                width: "40%",
                height: "40px",
                fontSize: "20px",
                marginTop: "15px",
                background: "#0080FF",
                borderRadius: "10px",
              }}
              type={"primary"}
              onClick={() => setShowOrders(true)}
            >
              View Orders
            </Button>
          </OuterContainer>
        }
        onBack={null
          // () => navigate("/dashboard") --
        }
      />

      <StackActivity
        visible={showOrders}
        setVisible={setShowOrders}
        type="orderList"
      />
    </>
  );
};

export default EcommerceStore;

const OuterContainer = styled.div`
  padding: 3%;
`;

const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Stats = styled.div`
  width: 45%;
  border: 1px solid gray;
  align-items: flex-start;
  display: flex;
  justify-content: center;
  height: 100px;
  border-radius: 6px;
  margin: 10px;
  flex-direction: column;
  padding: 4px;
`;

const StoreLink = styled.div`
  margin-bottom: 20px;
  margin-top: 15px;
`;
