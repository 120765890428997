import { isProdEnv } from "./utils";
import {isMobileApp, sendDataToApp} from "./utils/webviewUtils";
import {iLog} from "./utils/webViewUtilsV2/interfaces";

const isLogsForwardingEnabled = false;

const overrideLogger = () => {
    const nativeConsoleLog = console.log;

    if (isMobileApp() && isLogsForwardingEnabled) {
        console.log = (...args) => {
            try {
                // sendDataToApp({type: "logging", payload: args}, nativeConsoleLog);
                iLog(args.join("::"))
            } catch(err) {
            }
            return nativeConsoleLog(...args);
        }

        console.error = console.log;
        console.warn = console.log;
        console.debug = console.log;
    }


}

const overrideBackBtnHandler = () => {
    const handleBackButton = (event) => {
        event.preventDefault();
        // Custom back button behavior here
        console.log("## Back Event V2 ##");

        // Navigate to a different page or do something else
        // history.push('/some-other-page');
    };

    window.addEventListener('popstate', handleBackButton);

}

let appInitDone = false;
export const initApp = () => {
    if (appInitDone) {
        return;
    }
    appInitDone = true;
    overrideLogger();
    // overrideBackBtnHandler();
}