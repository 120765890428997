import {Toast} from "antd-mobile";
import {isAndroidApp} from "./webViewUtilsV2/interfaces";

export const sendDataToApp = (msg, logger = console.log) => {
  //   const codeTxt = `
  //       const {Alert} = RN;
  //       const runIt = async () => {
  //   try {
  //     Alert.alert(
  //     "Alert Title",
  //     "${msg}",
  //     [
  //       {
  //         text: "Cancel",
  //         onPress: () => console.log("Cancel Pressed"),
  //         style: "cancel"
  //       },
  //       { text: "OK", onPress: () => console.log("OK Pressed") }
  //     ]
  //   );
  //   } catch (error) {
  //   }
  // };
  // runIt();
  //   `;
    // const newMsg = JSON.stringify(Object.keys(window.ReactNativeWebView || {}));
    // logger("## Sending Data to app ##", msg);
    if (msg.type === "checkPermissions") { // legacy support - custom code
        const customCode = `
        try {
             const {PermissionsAndroid} = RN;
             RN.PermissionsAndroid.request(
                RN.PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
                {
                    title: "Storage Permission",
                    message: "${msg.payload.message}",
                    buttonNeutral: "Ask Me Later",
                    buttonNegative: "Cancel",
                    buttonPositive: "OK"
                }
            ).then(granted => {
                sendDataToWeb("checkPermissions", granted === RN.PermissionsAndroid.RESULTS.GRANTED ? "yes" : "no");
            }).catch(() => {
                sendDataToWeb("checkPermissions",  "no");
            });
        } catch (err) {
            console.log("## Failed to check n request permission ##", err);
            sendDataToWeb("checkPermissions",  "no");
        }
        `;

        // const code = `
        //     console.log("## TEST CODE ##");
        // `;
        msg = {
            type: "customCode",
            payload: customCode
        };
    }

    if (msg.type === "setOnBack") {
        const customCode = `
        try {
            if (!RN.newAddHandler) {
                RN.newAddHandler = RN.BackHandler.addEventListener;
            }
             RN.newAddHandler('hardwareBackPress', () => {
                    console.log("## Back Pressed Handler ##");
                    sendDataToWeb("backPressed");
                    return true;
             });
             
             RN.BackHandler.addEventListener = () => {console.log("## Will not init onBack now ##")};
        } catch (err) {
            console.log("## Failed to override OnBack ##", err);
            sendDataToWeb("checkPermissions",  "no");
        }
        `;

        msg = {
            type: "customCode",
            payload: customCode
        };
    }

    // logger("## Posting msg ##");
    window.ReactNativeWebView?.postMessage(JSON.stringify(msg));
}

export const appListener = (type, callback, retainListner = false) => {
    window.nativeApi[type] = (packet) => {
        try {
            callback(packet);
            if (!retainListner) {
                delete window.nativeApi[type];
            }
        } catch (err) {
            console.error("## Error while parsing App Data Packet ##", err);
        }
    }
}

export const isMobileApp = () => {
    return isAndroidApp();
}

export const requestPermissions = (message) => {
    if (!message) {
        message = "KhataBuddy needs storage permission so you can save invoices in your phone.";
    }
    console.log(`Requesting permission`);
    return;
    return new Promise(resolve => {
        console.log("## Requesting check permissions ##");
        appListener("checkPermissions", (stringResponse) => {
            const result = stringResponse === "yes";
            console.log("## RES TYPE ##", typeof result);
            setPermissionsGranted(result);
            resolve(result);
        });
        sendDataToApp({type: "checkPermissions", payload: {message}});
    });
}

export const setPermissionsGranted = (value) => {
    window.appPermissions = value;
}

export const arePermissionsGranted = () => {
    return !!window.appPermissions;
}

export const initBackPressed = (navigate) => {
    console.log("## Initializing Back ##");
    appListener("backPressed", () => {
        console.log("## Back from App ##");
        if (window.customOnBack) {
            window.customOnBack();
            return;
        }

        const backMap = {
            "/help": "/settings",
            "/profile": "/settings",
            "settings/invoice": "/settings",
            "/settings/company": "/settings",
            "/password": "/settings",
            "/edit/": "/vouchers/invoice",
            "/create": "/vouchers/invoice",
            "/view/": "/vouchers/invoice",
            "/vouchers": "/dashboard",
        }

        const keys = Object.keys(backMap);
        let target;
        for (const key of keys) {
            if (window.location.pathname.includes(key)) {
                target = backMap[key];
                break;
            }
        }

        if (target) {
            navigate(target);
        } else {
            // handle exit here:
            if (window.alreadyBacked) {
                const exitCode = `RN.BackHandler.exitApp();`;
                sendDataToApp({type: "customCode", payload: exitCode});
            } else {
                Toast.show({
                    // icon: 'loading',
                    content: "Press again to close app",
                    duration: 2000,
                    position: "bottom"
                });
                window.alreadyBacked = true;
                setTimeout(() => {
                    window.alreadyBacked = false;
                }, 2000);
            }
        }
    });
}

export const initBackPressedV2 = () => {
    appListener("backPressedV3", () => {
        console.log("## backPressedV3 called ##");
        const signal = window?.onBackV2?.();
        if (signal) { // back worked
            console.log("## GoingBack ##");
            return;
        }

        console.log("## Back Exit App ##");
        // couldn't go back:
        if (window.alreadyBacked) {
            // const exitCode = `RN.BackHandler.exitApp();`;
            // sendDataToApp({type: "customCode", payload: exitCode});
        } else {
            Toast.show({
                // icon: 'loading',
                content: "Press again to close app",
                duration: 2000,
                position: "bottom"
            });
            window.alreadyBacked = true;
            setTimeout(() => {
                window.alreadyBacked = false;
            }, 2000);
        }
    }, true);
}

export const emitAppLoadedSignal = () => {
}


window.nativeApi = {};